import React, { Component } from 'react'
import Axios from 'axios';
import IMComponent from '../Components/IMComponent';

import { Link } from 'react-router-dom';

import { Modal, Button, Grid, Icon } from 'semantic-ui-react';

import applicationActionCreator from '../../redux/actions/applicationActions';
import { messages } from '../../components/utility/Helper';

import LicenseUsageGrid from './LicenseUsagesGrid';
import LicenseUsagesHeader from './LicenseUsagesHeader';
import LicenseUsagesNewRow from './LicenseUsagesNewRow';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const componentURL = "/api/Licenses/{0}/LicenseUsages";
export let addNewUsage;
class List extends IMComponent {
  constructor(props) {
    super(props);
    props.showLoading("LicenseUsage_Started");
    const { match: { params: { licenseId } } } = this.props;
    this.state = {
      licenseId: licenseId,
      duplicateOrganizationId: "",
      duplicateLicenseId: "",
      duplicateEmail: "",
      duplicateUserId: "",
      duplicateRoleId: "",
      duplicatedUsage: "",
      revokemode: false,
      addUserFromOtherOrganizationMode: false,
      updateUserOrganizationMode: false,
      updateUserOrganizationOption: null,
      previousLurId: null,
      revokeReason: 'User revoke',
      offlineChecked: this.props.data == null ? false : this.props.data.Offline,
      renew: this.props.data == null ? false : this.props.data.ToRenew,
      maximumSessions: {},
      currentLicense: {},
      data: [],
      usages: [],
      total: 0,
      licenseEndDate: "",
      loadData: true,
      loadRolesData: true,
      addingUserFromOtherOrganization: false
      };
      this.loadLicenseUsages = this.loadLicenseUsages.bind(this);
      this.loadRolesFromServer = this.loadRolesFromServer.bind(this);

    this.loadLicenseUsages();
    this.loadRolesFromServer();
    addNewUsage = this.onAddNewUsage.bind(this);
    this.onAddNewUsage = this.onAddNewUsage.bind(this);
    this.onAddNewUsageWithDuplicateRevoking = this.onAddNewUsageWithDuplicateRevoking.bind(this);
    this.onAddNewUsageSimple = this.onAddNewUsageSimple.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOfflineChecked = this.handleOfflineChecked.bind(this);
    this.hasdataChanged = this.hasdataChanged.bind(this);
    this.handleRenewChecked = this.handleRenewChecked.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleAddUserFromOtherOrganization = this.handleAddUserFromOtherOrganization.bind(this);
    this.handleAddUserFromOtherOrganizationCancel = this.handleAddUserFromOtherOrganizationCancel.bind(this);
    this.handleUpdateUserOrganization = this.handleUpdateUserOrganization.bind(this);
    this.handleUpdateUserOrganizationNo = this.handleUpdateUserOrganizationNo.bind(this);
  }

  onAddNewUsage = function (usage) {
      usage.licenseCryptId = this.state.licenseId;
      this.props.showLoading("ADD_NEW_USAGE");
      if (this.state.addUserFromOtherOrganizationMode || this.state.updateUserOrganizationMode) {
          this.onAddNewUsageWithDuplicateRevoking(usage);
      }
      else {
          this.onAddNewUsageSimple(usage);
      }
  }

  onAddNewUsageSimple = function (usage) {
        usage.licenseCryptId = this.state.licenseId;
        const self = this;
        this.props.showLoading("ADD_NEW_USAGE");
        // CheckLinkingToOrganization
        return Axios.put(`api/Licenses/encrypted/${this.state.licenseId}`)
            .then(function() {
                Axios.post(`api/Licenses/${self.state.licenseId}/LicenseUsages/DuplicateOrganizationId/${self.state.duplicateOrganizationId}`, usage)
                    .then(function() {
                        self.setState({ usages: [], total: 0, canShowRoles: false });
                        return self.reloadData();
                        //return self.loadLicenseUsages();
                    })
                    .then(function() {
                        self.props.successMessage({
                            title: "Usage License",
                            message: messages.success.usageWasAddedSucessfully
                        });
                    })
                    .catch(function(ex) {
                        self.setState({ something: ex });
                        if (ex.response.data.message === 'User is part of other organization') {
                            self.setState({
                                addUserFromOtherOrganizationMode: true,
                                duplicateOrganizationId: ex.response.data.organizationId,
                                duplicateEmail: ex.response.data.email,
                                duplicatedUsage: usage
                            });
                        }
                        else if (ex.response.data.message === 'User is part of other organization2')
                        {
                            console.log('ex response: ');
                            console.log(ex.response.data);
                            console.log(ex.response);
                            self.setState({
                                updateUserOrganizationMode: true,
                                duplicateOrganizationId: ex.response.data.organizationId,
                                duplicateEmail: ex.response.data.email,
                                duplicatedUsage: usage,
                                previousLurId: ex.response.data.previousLurId
                            });
                        }
                        else {
                            self.props.errorMessage({ title: "Usage Error", message: ex.response.data });
                            self.setState({
                                duplicateOrganizationId: "",
                                addUserFromOtherOrganizationMode: false
                            });
                        }

                        self.setState({ addingUserFromOtherOrganization: false });
                    })
                    .then(function() {
                        if (self.state.addingUserFromOtherOrganization === false) {
                            self.props.hideLoading("ADD_NEW_USAGE");
                        }
                    });
            });
    }

    onAddNewUsageWithDuplicateRevoking = function(usage) {
        usage.licenseCryptId = this.state.licenseId;
        const self = this;
        this.props.showLoading("ADD_NEW_USAGE");
        if (this.state.updateUserOrganizationMode) {
            usage.updateUserOrganizationOption = this.state.updateUserOrganizationOption;
        }
        var um = this.state.updateUserOrganizationMode;
        this.setState({ addUserFromOtherOrganizationMode: false, updateUserOrganizationMode: false });
        return Axios
            .post(`api/Licenses/${self.state.licenseId}/LicenseUsages/DuplicateOrganizationId/${self.state.duplicateOrganizationId}`, usage)
            .then(function (response) {
                if (um) {
                    console.log(`previousLurId: ${self.state.previousLurId}`);
                    Axios.delete(`/api/Licenses/${self.state.licenseId}/LicenseUsages/${self.state.previousLurId}?informCloud=false`);
                }
                else {
                    const url = `/api/Licenses/${self.state.licenseId}/LicenseUsages/userWithOrganization`;
                    Axios.post(url,
                        {
                            EncryptedOrganizationId: self.state.duplicateOrganizationId,
                            EncryptedEmail: self.state.duplicateEmail
                        });
                }
            })
            .catch (function(e) {
                self.props.hideLoading("ADD_NEW_USAGE");
                if (!!e.response && e.response.status === 400)
                    self.showToastError({ title: "Revoke duplicate user", message: e.response.data });
                else
                    self.showToastError({
                        title: "Revoke duplicate user",
                        message: "There were some error please contact to Administrators."
                    });
            })
            .then(function() {
                //self.setState({
                //    usages: [],
                //    total: 0,
                //    canShowRoles: false
                //});

                self.setState({
                    duplicateOrganizationId: "",
                    addUserFromOtherOrganizationMode: false,
                    addingUserFromOtherOrganization: false
                });

                self.props.successMessage({
                    title: "Usage License",
                    message: messages.success.usageWasAddedSucessfully
                });
                self.props.hideLoading("ADD_NEW_USAGE");

                return self.loadLicenseUsages();
            })
            .catch(function(e) {
                self.props.hideLoading("ADD_NEW_USAGE");
                if (!!e.response && e.response.status === 400)
                    self.showToastError({ title: "Revoke duplicate user", message: e.response.data });
                else
                    self.showToastError({
                        title: "Revoke duplicate user",
                        message: "There were some error please contact to Administrators."
                    });
            });
    }

  loadRolesFromServer() {
    const self = this;
    return Axios.get(`api/Roles/${this.state.licenseId}`)
      .then(function (response) {
        self.setState({ roles: response.data });
      })
      .catch(function (e) {
        // ToDo: Show alert message is changing max sessions fails
        console.error(e.status, e.toString());
      })
      .then(function () {
          self.setState({ loadRolesData: false });
      });
  }

  loadLicenseUsages = function () {
    const self = this;
    const url = componentURL.format(this.state.licenseId);
    return Axios.get(url, { params: { version: Math.random() } })
      .then(function (response) {

        self.setState({
          usages: response.data.usages,
          total: response.data.total,
          canShowRoles: response.data.canShowRoles,
          endDate: response.data.endDate,
          startDate: response.data.startDate
        });
      })
      .catch(function (ex) {
        console.log(ex);
      })
      .then(function () {
          self.setState({ loadData: false });
          if (self.state.addingUserFromOtherOrganization === false) {
              self.props.hideLoading('LOAD_USAGES');
          }
      });
  }

  revokeClicked = (e) => {
    this.setState({
      revokemode: true,
      id: e.target.getAttribute('id'),
      index: e.target.getAttribute('index')
    });
  }

  removeSessionsClick = (e) => {
    e.preventDefault();
    this.setState({
      removemode: true,
      id: e.target.getAttribute('id'),
      index: e.target.getAttribute('index')
    });
  }

  handleConfirmation = function () {
    this.props.showLoading('CONFIRMATION_MODAL');
    const { removemode, revokemode } = this.state
    const self = this;
    this.setState({
      revokemode: false,
      removemode: false
    });
    const url = revokemode ? `/api/Licenses/${this.state.licenseId}/LicenseUsages/${this.state.id}` :
      removemode ? `/api/Session/${this.state.licenseId}/LicenseUsages/${this.state.id}` : '';
    return Axios.delete(url)
      .then(function (response) {
        if (response) {
          self.props.successMessage({ title: "Revoke Usage", message: "Usage was revoked successfully." })
          return self.loadLicenseUsages();
        }
      })
      .catch(function (e) {
          if (!!e.response && e.response.status === 400)
              self.showToastError({ title: "Revoke user", message: e.response.data });
          else
              self.showToastError({
                  title: "Revoke user",
                  message: "There were some error please contact to Administrators."
              });
      })
      .then(function () {
        self.props.hideLoading('CONFIRMATION_MODAL');
      });
  }

  hasdataChanged() {
    return (this.state.offlineChecked === this.state.data.Offline) &&
      (this.state.renew === this.state.data.ToRenew) ? false : true;
  }

  handleCancel() {
    this.setState({
      revokemode: false,
      removemode: false
    });
    }

    handleAddUserFromOtherOrganization = function () {
        this.setState({ addingUserFromOtherOrganization: true });
        this.onAddNewUsage(this.state.duplicatedUsage);
    }

    handleUpdateUserOrganization = function () {
        this.state.updateUserOrganizationOption = true;        
        this.onAddNewUsage(this.state.duplicatedUsage);
    }

    handleUpdateUserOrganizationNo = function () {
        this.state.updateUserOrganizationOption = false;
        this.onAddNewUsage(this.state.duplicatedUsage);
    }

  handleAddUserFromOtherOrganizationCancel() {
      this.setState({
          addUserFromOtherOrganizationMode: false,
          duplicateOrganizationId: ""
      });
  }

  handleOfflineChecked(e) {
    this.setState({
      offlineChecked: e.target.checked,
    });
  }
  handleRenewChecked(e) {
    this.setState({
      renew: e.target.checked,
    });
  }

  handleDataChange(e, id) {
    let maximumSessions = Object.assign({}, this.state.maximumSessions);
    switch (e.target.name) {
      case 'maximumSessions':
        maximumSessions[id] = e.target.value;
        this.setState({
          LicenseId: e.target.getAttribute('licenseid'),
          Index: e.target.getAttribute('index'),
          Id: e.target.id,
          maximumSessions
        });
        break;
      default:
        break;
    }
    return e.target.value;
  }

  handleKeyPress(e, item) {
    let maximumSessions = Object.assign({}, this.state.maximumSessions);
    maximumSessions[item.id] = e.target.value;
    this.setState({
      LicenseId: item.LicenseId,
      Index: e.target.getAttribute('index'),
      Id: item.id,
      maximumSessions
    });
    if (e.target.value !== 2) {
      if (e.charCode === 13) {
        this.handleUpdate(e.target.value, e.target.getAttribute('index'), item);
      }
    }
  }

  handleUpdate(value, index, item) {
    let userdata = this.state.data[index];
    userdata.MaximumSessions = value;
    const self = this;

    return Axios.put('api/Licenses/' + item.LicenseId + '/LicenseUsages/' + item.Id, userdata, { responseType: 'text' })
      .then(function (response) {
        if (response) {
          self.props.handleFilter(item.LicenseId);
        }
      })
      .catch(function (e) {
        // ToDo: Show alert message is changing max sessions fails
        console.error(e.status, e.toString());

      });
  }

  reloadData = () => {
    this.setState({ loadData: true });
    this.loadLicenseUsages();
  }

  render() {
      const { usages, revokemode, removemode, addUserFromOtherOrganizationMode, updateUserOrganizationMode, previousLurId, updateUserOrganizationOption, total, licenseId, canShowRoles, roles, endDate, startDate, loadData, loadRolesData } = this.state;
    const canAddUsage = usages.length < total;
    const modalRevoke = <Modal open={revokemode || removemode}>
      <Modal.Header>
        {revokemode ? 'Revoke usage' : ''}
        {removemode ? 'Remove sessions' : ''}
      </Modal.Header>
      <Modal.Content>
        {revokemode ? 'Do you really want to revoke this user?' : ''}
        {removemode ? 'Do you really want to remove the sessions of this user?' : ''}
      </Modal.Content>
      <Modal.Actions>        <Button primary onClick={this.handleConfirmation}>Yes</Button>
        <Button secondary onClick={this.handleCancel} content='Cancel' />
      </Modal.Actions>
      </Modal>;

      const modalAddUserFromOtherOrganization = <Modal open={addUserFromOtherOrganizationMode}>
          <Modal.Header>Add user from other organization</Modal.Header>
          <Modal.Content>The currently added user is already part of another Cloud Organization, do you want to update it?</Modal.Content>
          <Modal.Actions>
              <Button onClick={this.handleAddUserFromOtherOrganization} color="orange">YES</Button>
              <Button color="blue" onClick={this.handleAddUserFromOtherOrganizationCancel} content='CANCEL' />
          </Modal.Actions>
      </Modal>;

      const modalUpdateUserOrganization = <Modal open={updateUserOrganizationMode}>
          <Modal.Header>Update user's license</Modal.Header>
          <Modal.Content>The currently added user is already part of another Cloud Organization, do you want to update the Organization of the License?</Modal.Content>
          <Modal.Actions>
              <Button onClick={this.handleUpdateUserOrganization} color="orange">YES</Button>
              <Button color="blue" onClick={this.handleUpdateUserOrganizationNo} content='NO' />
          </Modal.Actions>
      </Modal>;

    if (this.state.loadData)
      return null;
    return <div>
        <Link to='/Licenses' className="floated right" style={{ marginTop: "10px" }}>
        <Icon name='close' />
      </Link>
      <LicenseUsagesHeader total={total} current={usages.length} licenseId={licenseId} isAdmin={this.props.isAdmin} endDate={endDate} startDate={startDate} reloadData={this.reloadData} />
      <Grid style={{ width: "100%", maxWidth: "none" }}>
        {usages.length ?
          <LicenseUsageGrid data={usages} roles={roles} showRole={canShowRoles} onRevokeClick={this.revokeClicked} isAdmin={this.props.isAdmin} onRemoveSessionsClick={this.removeSessionsClick} licenseEndDate={endDate}></LicenseUsageGrid>
          : <LicenseUsageGrid data={[]} roles={roles} showRole={canShowRoles} onRevokeClick={this.revokeClicked} isAdmin={this.props.isAdmin} onRemoveSessionsClick={this.removeSessionsClick} licenseEndDate={endDate}></LicenseUsageGrid>
        }
        {canAddUsage && !loadData && !loadRolesData ? <LicenseUsagesNewRow canShowRoles={canShowRoles} roles={roles} /> : null}
      </Grid>
        {modalRevoke}
        {modalAddUserFromOtherOrganization}
        {modalUpdateUserOrganization}
    </div>;

  }
}

function mapDispatchToProps(dispatch) {
  return {
    showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
    hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
    successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
    errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) },
  }
}

export default connect(null, mapDispatchToProps)(List);